<template>
  <div>
    <div>
      <b-row>
        <b-card class="bg-gradient-primary icon-banner m-2">
          <b-row>
            <b-col lg="2" md="3" class="align-self-center">
              <img src="/assets/images/logo/yaplay_white_icon.png" />
            </b-col>
            <b-col class="align-self-center">
              <h1 class="text-white">Bem vindos ao YaPlay!</h1>
              <h4>Sua Plataforma de Jogos em Psicologia</h4>
              <h5 md="3" class="text-white">
                Aqui você pode cadastrar seus pacientes, agendar suas sessões,
                registrar seus dados clínicos e mais importante, acompanhar sua
                evolução a partir do uso dos jogos da plataforma. Acompanhe tudo
                a partir de painéis e gráficos construídos para, visualmente,
                você acompanhar ao longo do tempo o efeito dos jogos em cada
                caso clínico.
              </h5>
            </b-col>
          </b-row>
        </b-card>
      </b-row>

      <h1>Consultas recentes</h1>
      <swiper
        class="swiper-centered-slides p-1"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <!-- slides -->
        <!-- <swiper-slide v-for="data in swiperDataRecents" :key="data.id">
          <paciente-card :appointment="data" />
        </swiper-slide> -->

        <swiper-slide v-for="data in swiperDataRecents" :key="data.id">
          <router-link :to="'/consulta/' + data.id ">
          <b-card no-body class="h-100">
            <b-card-body
              class="d-flex justify-content-start align-items-center"
            >
              <b-avatar size="70px" variant="light-primary" text="JG" />
              <div class="truncate ml-2">
                <h2 class="mb-25 font-weight-bolder">
                  {{ data.patient.physicalPerson.firstName }}
                </h2>
                
                <span class="mb-25 font-weight-bolder"> 
                  {{ data.patient.physicalPerson.lastName }}
                   </span>
                <div>
                  <span class="small">{{ getFromNowByDate(data.startDate) }}</span>
                </div>
                <div >
                  <span class="small text-danger" v-if="data.status != 'CLOSE'">Não concluído</span>
                </div>
              </div>
            </b-card-body>
          </b-card>
          </router-link>
        </swiper-slide>

        <!-- Add Arrows -->
        <div slot="button-next" class="swiper-button-next" />
        <div slot="button-prev" class="swiper-button-prev" />
      </swiper>
    </div>
    <div class="mt-3">
      <h1>Próximas Consultas</h1>
      <swiper
        class="swiper-centered-slides p-1"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <!-- slides -->
        <swiper-slide v-for="data in swiperDataNexts" :key="data.id">
          <agenda-card :agenda="data" />
        </swiper-slide>

        <!-- Add Arrows -->
        <div slot="button-next" class="swiper-button-next" />
        <div slot="button-prev" class="swiper-button-prev" />
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import PacienteCard from '@/components/cards/PacienteCard.vue'
import AgendaCard from '@/components/cards/AgendaCard.vue'
import medicalAppointmentService from '@/services/medicalAppointmentService'
import { BCard, BCardBody, BAvatar, BRow, BCol } from 'bootstrap-vue'
import utilsService from '@/services/utilsService'

export default {
  components: {
    Swiper,
    SwiperSlide,
    PacienteCard,
    AgendaCard,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
  },
  data: () => ({
    agenda: [
      {
        id: 1,
        tipo: 'Teste Cognitivo',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Aplicação do Jogo',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Jogo Teste',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Devolutiva',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
    ],
    swiperDataRecents: [],
    swiperDataNexts: [],
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: false,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
  }),
  methods: {
    ...utilsService,
    async getRecentsPatient() {
      this.swiperDataRecents =
        await medicalAppointmentService.getRecentsPatient()
        console.log(swiperDataRecents)
    },
    async getNextsPatient() {
      this.swiperDataNexts = await medicalAppointmentService.getNextsPatient()
    },
  },
  mounted() {
    this.getRecentsPatient()

    this.getNextsPatient()
  },
}
</script>
